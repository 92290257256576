import { getCmsBlocks } from '@core_modules/theme/services/graphql';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';
import cx from 'classnames';

const CampaignRight = (props) => {
    const { storeConfig } = props;

    const { data, loading, error } = getCmsBlocks({
        identifiers: 'floating_sticky_button',
    });

    const content = data?.cmsBlocks?.items[0]?.content;

    return (
        <>
            {content && !loading && !error && (
                <div
                    className={cx(
                        'swift-floating_button leading-[0] animate-anima-elastic',
                    )}
                >
                    <CmsRenderer content={content} storeConfig={storeConfig} />
                </div>
            )}
        </>
    );
};

export default CampaignRight;
